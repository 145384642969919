import axios from "axios";
import Cookies from "js-cookie";
import { useAuth } from "../hooks/useAuth";

const api = axios.create({
  // baseURL: "http://3.110.36.140/api", // for test
  // baseURL: "https://gms.ind.in/api",
  // baseURL: "http://13.234.233.198:8000/api",
  baseURL: "/api",
  // baseURL: "http://13.234.233.198:8000/api",
  // baseURL: "https://portal.erunadev.co.in/api",
});

api.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token = Cookies.get("myToken");
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("userData");
      Cookies.remove("myToken");
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;

import cogoToast from "cogo-toast";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { DatePicker, Input, Modal } from "rsuite";
import { useAuth } from "../../hooks/useAuth";
import api from "../../lib/api";
import { changeTimeFormat } from "../../lib/time";
import SaveApproveModalV1 from "./SaveApproveModalV1";

const AttendanceDetailsModalV1 = ({
  editModalOpen,
  setEditModalOpen,
  empData,
  setEmpData,
  refetchData,
  ip,
}) => {
  const [imagePath, setImagePath] = useState("");
  const [justification, setJustification] = useState("");
  const [regularHrs, setRegularHrs] = useState();
  const [otHrs, setOtHrs] = useState();
  const [validateData, setValidateData] = useState();
  const [validationMsg, setValidationMsg] = useState("");
  const [dateAltered, setDateAltered] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const { user } = useAuth();
  const accessDetails = JSON.parse(user?.accessDetails);

  useEffect(() => {
    if (
      empData?.imagename &&
      empData?.imagename !== "" &&
      editModalOpen === true
    ) {
      getImage({
        filename: empData?.imagename,
        action: "att",
      });
    } else {
      setImagePath();
    }
    // eslint-disable-next-line no-use-before-define
  }, [editModalOpen, empData?.imagename]);

  useEffect(() => {
    if (empData) {
      let approveInTemp = new Date(empData?.ApprovedIn);
      approveInTemp.setSeconds(0);
      let approveOutTemp = new Date(empData?.ApprovedOut);
      approveOutTemp.setSeconds(0);
  
      setRegularHrs(approveInTemp);
      setOtHrs(approveOutTemp);
      setJustification(empData.justification);
    }
  }, [empData]);

  const { mutate: getImage, isLoading } = useMutation(
    (data) => api.post("/Customer_SI_getimage", data), // for iconnect portal
    {
      onSuccess: (res) => {
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
          setImagePath("");
        } else {
          setImagePath(res.data);
        }
      },
    }
  );

  const { mutate: editSave, isLoading: editSaveLoading } = useMutation(
    (data) => api.post("CustomerAttendanceApproval_V1/Set", data),
    {
      onSuccess: (res) => {
        setApproveModalOpen(false);
        setEditModalOpen(false);
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
        } else {
          cogoToast.success(`Record Saved Successfully`);
          setEmpData();
          setValidateData();
          setRegularHrs();
          setOtHrs();
          setJustification("");
          setDateAltered(false);
          refetchData();
          setValidationMsg("");
        }
      },
    }
  );

  const ValiDate = () => {
    if (dateAltered === true) {
      if (justification !== "") {
        editSave({
          user_id: user?.userid,
          id: [JSON.parse(empData?.id)],
          action: "Validated",
          validatedBy_IP: ip,
          regularHrs: moment(regularHrs).format("HH:mm"),
          otHrs: moment(otHrs).format("HH:mm"),
          comments: justification,
          totalPerfHrs: totalPerfHrs,

          // user_id: user?.userid,
          // id: JSON.stringify(empData?.id),
          // action: "EditSave",
          // modifiedBy_IP: ip,
          // approvedIn: moment(regularHrs).format("YYYY-MM-DD HH:mm:ss.SSS"),
          // approvedOut: moment(otHrs).isAfter(regularHrs)
          //   ? moment(otHrs).format("YYYY-MM-DD HH:mm:ss.SSS")
          //   : moment(otHrs).add(1, "d").format("YYYY-MM-DD HH:mm:ss.SSS"),
          // ApprovedHrs: moment
          //   .utc(moment(otHrs, "HH:mm").diff(moment(regularHrs, "HH:mm")))
          //   .add(1, "second")
          //   .format("HH:mm"),
        });
      } else {
        setValidationMsg("This Field Is Required");
      }
    } else {
      editSave({
        // user_id: user?.userid,
        // id: JSON.stringify(empData?.id),
        // action: "EditSave",
        // modifiedBy_IP: ip,
        // approvedIn: moment(regularHrs).format("YYYY-MM-DD HH:mm:ss.SSS"),
        // approvedOut: moment(otHrs).isAfter(regularHrs)
        //   ? moment(otHrs).format("YYYY-MM-DD HH:mm:ss.SSS")
        //   : moment(otHrs).add(1, "d").format("YYYY-MM-DD HH:mm:ss.SSS"),
        //   ApprovedHrs: moment
        //   .utc(moment(otHrs, "HH:mm").diff(moment(regularHrs, "HH:mm")))
        //   .add(1, "second")
        //   .format("HH:mm"),
        // justification: justification,

        user_id: user?.userid,
        id: [JSON.parse(empData?.id)],
        action: "Validated",
        validatedBy_IP: ip,
        regularHrs: moment(regularHrs).format("HH:mm"),
        otHrs: moment(otHrs).format("HH:mm"),
        comments: justification,
        totalPerfHrs: totalPerfHrs,
      });
    }
  };

  // Create a tempOtHrs and tempRegularHrs with hours
  let tempOtHrs = moment.duration(moment(otHrs).format("HH:mm"));
  let tempRegularHrs = moment.duration(moment(regularHrs).format("HH:mm"));

  // Convert hours to minutes
  let minutes1 = tempOtHrs.asMinutes();
  let minutes2 = tempRegularHrs.asMinutes();

  // Create a duration object with minutes
  let duration = moment.duration(minutes1 + minutes2, "minutes");

  // Convert minutes to hours
  let hours = Math.floor(duration.asHours());
  let minutes = duration.minutes();

  // Create a moment object with the current date and the extracted hours and minutes
  let time = moment().hours(hours).minutes(minutes);

  // Format totalPerfHrs into HH:mm
  let totalPerfHrs = time.format("HH:mm");



  return (
    <Modal
      backdrop="static"
      className="modalFormSec attendApprDtlMdl attendApprDtlMdl2 attendanceDetailModal reportImgDetail qrCodeViewSec"
      size="md"
      open={editModalOpen}
      onClose={() => {
        setEditModalOpen(false);
        setEmpData();
        setRegularHrs();
        setOtHrs();
        setJustification();
        setDateAltered(false);
        setValidationMsg("");
      }}
    >
      <Modal.Header>
        <Modal.Title>Employee Attendance Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container row">
          <div className="row">
            <SaveApproveModalV1
              approveModalOpen={approveModalOpen}
              setApproveModalOpen={setApproveModalOpen}
              setEditModalOpen={setEditModalOpen}
              approvedData={validateData}
              setEmpData={setEmpData}
              refetchData={refetchData}
              setApproveData={setValidateData}
              setJustification={setJustification}
              setDateAltered={setDateAltered}
              setValidationMsg={setValidationMsg}
            />
            <div className="col-md-3">
              <figure>
                <h5>Employee Image</h5>
                <img
                  src={
                    imagePath
                      ? `data:image/jpeg;base64,${imagePath}`
                      : "../../../../images/icons/user/profile.svg"
                  }
                  alt="employee"
                  width={100}
                  height={100}
                />
              </figure>
            </div>
            <div className="col-md-9">
              <table className="text-left">
                <tbody>
                  <tr>
                    <td>State</td>
                    <td>:</td>
                    <td>{empData?.state}</td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>:</td>
                    <td>{empData?.city}</td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>:</td>
                    <td>{empData?.Address}</td>
                  </tr>
                  <tr>
                    <td>Schedule Date</td>
                    <td>:</td>
                    <td> {moment(empData?.ScheduleDate).format("DD-MM-yy")}</td>
                  </tr>
                  <tr>
                    <td>Post</td>
                    <td>:</td>
                    <td>{empData?.Post}</td>
                  </tr>
                  <tr>
                    <td>Rank</td>
                    <td>:</td>
                    <td>{empData?.Rank}</td>
                  </tr>
                  <tr>
                    <td>Shift</td>
                    <td>:</td>
                    <td>{empData?.shiftcode}</td>
                  </tr>
                  <tr>
                    <td>Shift Start Time</td>
                    <td>:</td>
                    <td>{`${empData?.shiftstart.split(":")[0]}:${
                      empData?.shiftstart.split(":")[1]
                    }`}</td>
                  </tr>
                  <tr>
                    <td>Shift End Time </td>
                    <td>:</td>
                    <td>{`${empData?.shiftend.split(":")[0]}:${
                      empData?.shiftend.split(":")[1]
                    }`}</td>
                  </tr>
                  {/* <tr>
                    <td>Contracted Hours</td>
                    <td>:</td>
                    <td>{empData?.ContHrs}</td>
                  </tr> */}
                  <tr>
                    <td>Emp Code</td>
                    <td>:</td>
                    <td>{empData?.EmployeeCode}</td>
                  </tr>
                  <tr>
                    <td>Emp Name</td>
                    <td>:</td>
                    <td>{empData?.EmployeeName}</td>
                  </tr>
                  <tr>
                    <td>Duty Date</td>
                    <td>:</td>
                    <td> {moment(empData?.DutyDate).format("DD-MM-yy")}</td>
                  </tr>
                  <tr>
                    <td>In Time</td>
                    <td>:</td>
                    <td> {moment(empData?.ApprovedIn).format("HH:mm")}</td>
                  </tr>
                  <tr>
                    <td> Out Time</td>
                    <td>:</td>
                    <td> {moment(empData?.ApprovedOut).format("HH:mm")}</td>
                  </tr>
                  <tr>
                    <td> Accual Hrs</td>
                    <td>:</td>
                    <td> {empData?.ApprovedDutyHrs}</td>
                  </tr>
                  <tr>
                    <td> Rounded Hrs</td>
                    <td>:</td>
                    <td> {empData?.RoundedHrs}</td>
                  </tr>
                  <tr>
                    <td> Regular Hrs </td>
                    <td>:</td>
                    <td>
                      <DatePicker
                        ranges={[]}
                        placement="auto"
                        value={regularHrs} //new Date(moment())
                        onChange={(val) => {
                          setDateAltered(true);
                          setRegularHrs(val);
                        }}
                        format="HH:mm"
                        cleanable={false}
                        editable={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Ot Hrs</td>
                    <td>:</td>
                    <td>
                      <DatePicker
                        ranges={[]}
                        placement="auto"
                        value={otHrs}
                        onChange={(val) => {
                          setDateAltered(true);
                          setOtHrs(val);
                        }}
                        format="HH:mm"
                        cleanable={false}
                        editable={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Total Performed Hrs</td>
                    <td>:</td>
                    <td>
                      {/* {moment
                        .utc(
                          moment(moment(otHrs, "HH:mm").toDate()).add(
                            moment(moment(regularHrs, "HH:mm").toDate())
                          )
                        )
                        .format("HH:mm")
                        } */}

                      {totalPerfHrs}
                    </td>
                  </tr>
                  <tr>
                    <td>Justification</td>
                    <td>:</td>
                    <td>
                      <Input
                        as="textarea"
                        value={justification}
                        onChange={(val) => {
                          setValidationMsg("");
                          setJustification(val);
                        }}
                        rows={3}
                        placeholder="Write Your justifications Here"
                      />
                      <span className="text-danger">{validationMsg}</span>
                    </td>
                  </tr>
                  <tr>
                    <td> Edited </td>
                    <td>:</td>
                    <td> {empData?.Edited}</td>
                  </tr>
                  <tr>
                    <td> Flag </td>
                    <td>:</td>
                    <td> {empData?.Flag}</td>
                  </tr>{" "}
                  <tr>
                    <td> Status</td>
                    <td>:</td>
                    <td> {empData?.status}</td>
                  </tr>
                </tbody>
              </table>
              <div className="modalBtn">
                <button
                  className="btn2 btnBlack"
                  onClick={() => {
                    setEditModalOpen(false);
                    setEmpData();
                    setRegularHrs();
                    setOtHrs();
                    setJustification();
                    setDateAltered(false);
                    setValidationMsg("");
                  }}
                >
                  Cancel
                </button>

                {accessDetails?.find(
                  (item) =>
                    item.option_name === "Reports_AttendanceApprovalV1_Set" &&
                    item.action_name === "Validated"
                ) !== undefined ? (
                  <button className="btn2 greenGrdClr" onClick={ValiDate}>
                    ValiDate
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(AttendanceDetailsModalV1);

import cogoToast from "cogo-toast";
import moment from "moment";
import React from "react";
import { useMutation } from "react-query";
import { Loader, Modal } from "rsuite";
import api from "../../lib/api";

const SaveApproveModal = ({
  approveModalOpen,
  setApproveModalOpen,
  setEditModalOpen,
  approvedData,
  setEmpData,
  refetchData,
  setApprovedData,
  setComment,
  setValidationMsg,
  setDateAltered,
}) => {
  const { mutate: editSave, isLoading: editSaveLoading } = useMutation(
    (data) => api.post("CustomerAttendanceApproval/Set", data),
    {
      onSuccess: (res) => {
        setApproveModalOpen(false);
        setEditModalOpen(false);
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
        } else {
          cogoToast.success(
            `Record ${
              approvedData?.action === "EditSave" ? "Saved" : "Saved & Approved"
            } Successfully`
          );
          setEmpData();
          setApprovedData();
          refetchData();
          setComment("");
          setValidationMsg("");
          setDateAltered(false);
        }
      },
    }
  );

  const save = () => {
    editSave({
      user_id: approvedData?.user_id,
      id: approvedData?.id,
      action: approvedData?.action,
      modifiedBy_IP: approvedData?.modifiedBy_IP,
      approvedIn: moment(approvedData.approvedIn).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      ),
      approvedOut: moment(approvedData.approvedOut).isAfter(
        approvedData.approvedIn
      )
        ? moment(approvedData.approvedOut).format("YYYY-MM-DD HH:mm:ss.SSS")
        : moment(approvedData.approvedOut)
            .add(1, "d")
            .format("YYYY-MM-DD HH:mm:ss.SSS"),
      ApprovedHrs: moment
        .utc(
          moment(approvedData.approvedOut, "HH:mm").diff(
            moment(approvedData.approvedIn, "HH:mm")
          )
        )
        .add(1, "second")
        .format("HH:mm"),
      comments: approvedData.comments,
    });
  };

  return (
    <Modal
      className="modalFormSec attendApprlModalSmall modelConfirmCreate"
      backdrop="static"
      keyboard={false}
      open={approveModalOpen}
      onClose={() => {
        setApprovedData();
        setApproveModalOpen(false);
        setEmpData();
        setComment("");
        setValidationMsg("");
        setDateAltered(false);
      }}
    >
      <Modal.Body>
        <img
          src="../../../../images/icons/attendance/info_icon.svg"
          alt="export_icon"
          width={40}
          height={40}
        />
        <p>
          Records once{" "}
          {approvedData?.action === "EditSave" ? "saved" : "saved & approved"}{" "}
          cannot be altered. Are you sure you want to continue?
        </p>
        <div>
          <button
            onClick={() => {
              setApproveModalOpen(false);
              // setEmpData();
              // setApprovedData();
              // setComment("");
              setValidationMsg("");
              // setDateAltered(false);
            }}
            className="btn2 btnBlack"
          >
            No
          </button>
          {editSaveLoading ? (
            <button className="btn2">
              <Loader />
            </button>
          ) : (
            <button onClick={save} className="btn2">
              Yes
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(SaveApproveModal);

import cogoToast from "cogo-toast";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import {
  Button,
  CheckPicker,
  DatePicker,
  Input,
  Loader,
  Modal,
  SelectPicker,
  Tooltip,
} from "rsuite";
import { useAuth } from "../../hooks/useAuth";
import api from "../../lib/api";
import { changeTimeFormat } from "../../lib/time";
import SaveApproveModalV1 from "./SaveApproveModalV1";
import { useSelector, useDispatch } from "react-redux";

import { format } from "date-fns";
import { ImpulseSpinner } from "react-spinners-kit";

const AttendanceDetailsModalAddNew = ({
  AddNewModal,
  setAddNewModal,
  empData,
  setEmpData,
  refetchData,
  ip,
}) => {
  const [imagePath, setImagePath] = useState("");
  const [justification, setJustification] = useState("");
  const [regularHrs, setRegularHrs] = useState();
  const [inTime, setInTime] = useState();
  const [otHrs, setOtHrs] = useState();
  const [outTime, setOutTime] = useState();
  const [validateData, setValidateData] = useState();
  const [validationMsg, setValidationMsg] = useState("");
  const [dateAltered, setDateAltered] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const { user } = useAuth();
  const accessDetails = JSON.parse(user?.accessDetails);
  const reduxDate = useSelector((state) => state.date);

  const [customerId, setCustomerId] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [assignment, setAssignments] = useState([]);
  const [dutyDate, setDutyDate] = useState("");
  const [employee, setEmployee] = useState("");
  const [post, setPost] = useState("");
  const [rank, setRank] = useState("");
  const [shift, setShift] = useState("");
  const [startShiftTime, setStartShiftTime] = useState("");
  const [endShiftTime, setEndShiftTime] = useState("");
  const [shiftDuration, setShiftDuration] = useState("");
  const [startShiftTimeTwo, setStartShiftTimeTwo] = useState("");
  const [endShiftTimeTwo, setEndShiftTimeTwo] = useState("");

  const [toolState, setToolState] = useState("");

  const [stateArray, setStateArray] = useState([]);
  const [cityArray, setCityArray] = useState([]);
  const [locationArray, setLocationArray] = useState([]);
  const [employeeArray, setEmployeeArray] = useState([]);
  const [postArray, setPostArray] = useState([]);
  const [rankArray, setRankArray] = useState([]);
  const [shiftArray, setShiftArray] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [newPostData, setNewPostData] = useState();
  const [newRankData, setNewRankData] = useState();
  const [newShiftData, setNewShiftData] = useState();
  const [newShiftArrayDependOnRank, setNewShiftArrayDependOnRank] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [openModal, setOpenModal] = useState(false);

  const [locationData, setLocationData] = useState();
  const [employeeData, setEmployeeData] = useState();

  const [customerArray, setCustomerArray] = useState([]);
  const [hour, setHour] = useState();
  const [actionType, setActionType] = useState("State");

  useEffect(() => {
    if (reduxDate) {
      setCustomerId(reduxDate.customerId[0]);
    }
  }, [reduxDate]);

  // useEffect(() => {
  //   if (AddNewModal == true) {
  //     setState("");
  //     setCity("");
  //     setLocation("");
  //     setDutyDate("");
  //     setEmployee("");
  //     setPost("");
  //     setRank("");
  //     setShift("");
  //     setInTime("");
  //     setOutTime("");
  //   }
  // }, [AddNewModal]);

  useEffect(() => {
    if (actionType === "") {
      setActionType("State");
    }

    getData({
      userid: user?.userid,
      customerId: customerId,
      state: state,
      city: city,
      assignmentId: location ? location?.toString() : "",
      empCode: `${employee}`,
      action: actionType,
      dutyDate: dutyDate ? `${moment(dutyDate).format("YYYY-MM-DD")}` : "",
    });
  }, [state, city, customerId, employee, location, dutyDate, actionType]);

  const { mutate: getData, isLoading: getDataLoading } = useMutation(
    (data) =>
      api.post(
        "/CustomerAttendanceApproval_V1/CustomerAttendanceApprovalFilter",
        data
      ),
    {
      onSuccess: (res) => {
        setScheduleData([]);
        setContractData([]);
        setPostArray([]);
        setRankArray([]);
        setShiftArray([]);
        setPost("");
        setRank("");
        setShift("");
        setStartShiftTime("");
        setEndShiftTime("");
        if (state === "") {
          setStateArray(JSON.parse(res.data.dropDown));
        } else if (state !== "" && city === "") {
          setCityArray(JSON.parse(res.data.dropDown));
        } else if (state !== "" && city !== "" && location === "") {
          setLocationArray(JSON.parse(res.data.dropDown));
        } else if (
          state !== "" &&
          city !== "" &&
          location !== "" &&
          employee === ""
        ) {
          setEmployeeArray(JSON.parse(res.data.dropDown));
        } else if (
          state !== "" &&
          city !== "" &&
          location !== "" &&
          employee !== ""
        ) {
          if (res.data.contract != null) {
            setContractData(JSON.parse(res.data.contract));
            // let postArray = JSON.parse(res.data.contract);

            // const uniqueNames = {};
            // const filteredData = postArray?.reduce((acc, obj) => {
            //   if (!uniqueNames[obj.post_code]) {
            //     uniqueNames[obj.post_code] = true;
            //     acc.push(obj);
            //   }
            //   return acc;
            // }, []);

            // setPostArray(filteredData);
          }

          if (res.data.schedule != null) {
            setScheduleData(JSON.parse(res.data.schedule));
            // setPostArray(JSON.parse(res.data.schedule));
          }
        }
      },
      onError: (error) => {
        //  console.log(error)
      },
    }
  );

  useEffect(() => {
    if (startShiftTime && endShiftTime) {
      try {
        const shiftTimeFrom = new Date(startShiftTimeTwo);
        const shiftTimeTo = new Date(endShiftTimeTwo);

        const newShiftTimeFrom = moment(shiftTimeFrom, "HH:mm");
        const newShiftTimeTo = moment(shiftTimeTo, "HH:mm");

        const shiftDuration = moment(newShiftTimeTo)?.diff(
          moment(newShiftTimeFrom),
          "hours"
        );

        setShiftDuration(shiftDuration);
      } catch (error) {
        // console.log("error on shift duration", error);
      }
    }
  }, [endShiftTime, startShiftTime]);

  // console.log("shiftDuration", shiftDuration);

  const handleManualSet = () => {


    console.log("newPostData",newPostData)

    ManualSet({
      user_id: user?.userid,
      employeeId: employeeData.employee_id,
      state: state,
      city: city,
      address: assignment.assignment_address,
      assignmentId: assignment.assignment_id,
      assignmentCode: assignment.assignment_code,
      customerCode: assignment.customer_code,
      branchCode: assignment.branch_code,
      post: post,
      rank: rank,
      soNo: newPostData?.so_no,
      soLineNo: newPostData?.so_line_no,
      shiftCode: shift,
      shiftStart: moment(newPostData?.shift_time_from).format("HH:mm"),
      shiftEnd: moment(newPostData?.shift_time_to).format("HH:mm"),
      shiftDuration: shiftDuration,
      // shiftDuration: 9,
      contractMp: newPostData?.ContractMP,
      action: "Validated",
      validatedBy_IP: ip,
      dutyDate: moment(dutyDate).format("YYYY-MM-DD"),
      regularHrs: regularHrs,
      otHrs: otHrs,
      totalPerfHrs: moment
        .utc(moment(outTime, "HH:mm").diff(moment(inTime, "HH:mm")))
        .add(1, "second")
        .format("HH:mm"),
      approvedIn: moment(inTime).format("HH:mm"),
      approvedOut: moment(outTime).format("HH:mm"),
      comments: justification,
    });
  };

  const { mutate: ManualSet, isLoading: ManualSetLoading } = useMutation(
    (data) => api.post("/CustomerAttendanceApproval_V1/ManualSet", data),
    {
      onSuccess: (res) => {
        if (res.data.response === "info") {
          setErrorMsg(res.data.errormsg);
          setOpenModal(true);
        } else if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
        } else if (res.data.response === "Success") {
          cogoToast.success(`Record Saved Successfully`);

          handleClear();
          setAddNewModal(false);
          // refetchData();
        }
      },
    }
  );

  const handleClear = () => {
    setState("");
    setCity("");
    setLocation("");
    setEmployee("");
    setPost("");
    setRank("");
    setShift("");
    setJustification("");
    setRegularHrs("");
    setOtHrs("");
    setDutyDate("");
    setActionType("");
    setCityArray([]);
    setLocationArray([]);
    setEmployeeArray([]);
    setPostArray([]);
    setRankArray([]);
    setShiftArray([]);
  };

  let totalPerfHrs = moment
    .utc(moment(outTime, "HH:mm").diff(moment(inTime, "HH:mm")))
    .add(1, "second")
    .format("HH:mm");

  let newRegularHrs =
    moment(totalPerfHrs, "hours") < moment(8, "hours")
      ? moment(totalPerfHrs, "hours").format("HH:mm")
      : "08:00";

  let newOtHrs = moment(totalPerfHrs, "HH:mm").diff(
    moment("08:00", "HH:mm"),
    "hours"
  );

  useEffect(() => {
    setRegularHrs(newRegularHrs);

    if (newOtHrs > 0) {
      setOtHrs(moment(newOtHrs, "hours").format("HH:mm"));
    } else {
      setOtHrs(moment(0, "hours").format("HH:mm"));
    }
  }, [newOtHrs, newRegularHrs]);

  useEffect(() => {
    if (employee !== "") {
      if (scheduleData.length > 0 && contractData.length === 0) {
        const uniqueNames = {};
        const newTempPostArrayData = scheduleData?.reduce((acc, obj) => {
          if (!uniqueNames[obj.post_code]) {
            uniqueNames[obj.post_code] = true;
            acc.push(obj);
          }
          return acc;
        }, []);
        setPostArray(newTempPostArrayData);
        setNewPostData(newTempPostArrayData[0]);
      } else {
        const uniqueNames = {};
        const newTempPostArrayData = contractData?.reduce((acc, obj) => {
          if (!uniqueNames[obj.post_code]) {
            uniqueNames[obj.post_code] = true;
            acc.push(obj);
          }
          return acc;
        }, []);

        setPostArray(newTempPostArrayData);
      }
    }
  }, [employee, scheduleData, contractData]);

  useEffect(() => {
    if (scheduleData.length > 0) {
      setPost(scheduleData[0]?.post_code);
    } else {
      if (postArray.length > 0 && postArray.length === 1) {
        setPost(scheduleData[0]?.post_code);
      } else {
        setPost(postArray[0]?.post_code);
      }
    }
  }, [postArray]);

  useEffect(() => {
    if (post !== "") {
      if (scheduleData.length > 0 && contractData.length === 0) {
        const uniqueNames2 = {};
        const newTempRankArrayData = scheduleData?.reduce((acc, obj) => {
          if (!uniqueNames2[obj.so_rank]) {
            uniqueNames2[obj.so_rank] = true;
            acc.push(obj);
          }
          return acc;
        }, []);

        setRankArray(
          newTempRankArrayData
            ?.filter((x) => x.post_code == post)
            .map((item) => {
              return {
                label: item?.so_rank,
                value: item?.so_rank,
                shift_code: item?.shift_code,
                shift_time_from: item?.shift_time_from,
                shift_time_to: item?.shift_time_to,
              };
            })
        );
      } else {
        const uniqueNames2 = {};
        const newTempRankArrayData = contractData?.reduce((acc, obj) => {
          if (!uniqueNames2[obj.so_rank]) {
            uniqueNames2[obj.so_rank] = true;
            acc.push(obj);
          }
          return acc;
        }, []);

        setRankArray(
          newTempRankArrayData
            ?.filter((x) => x.post_code == post)
            .map((item) => {
              return {
                label: item?.so_rank,
                value: item?.so_rank,
                shift_code: item?.shift_code,
                shift_time_from: item?.shift_time_from,
                shift_time_to: item?.shift_time_to,
              };
            })
        );
      }
    }
  }, [post, scheduleData, contractData]);

  useEffect(() => {
    if (scheduleData.length > 0) {
      setRank(scheduleData[0]?.so_rank);
    } else {
      if (rankArray.length > 0 && rankArray.length === 1) {
        setRank(rankArray[0]?.so_rank);
      }
    }
  }, [rankArray, scheduleData]);

  useEffect(() => {
    if (rank != "") {
      if (scheduleData.length > 0 && contractData.length === 0) {
        const uniqueNames3 = {};
        const newTempShiftArrayData = scheduleData?.reduce((acc, obj) => {
          if (!uniqueNames3[obj.so_rank]) {
            uniqueNames3[obj.so_rank] = true;
            acc.push(obj);
          }
          return acc;
        }, []);

        console.log("newTempShiftArrayData", newTempShiftArrayData);

        setShiftArray(
          newTempShiftArrayData
            ?.filter((x) => x.so_rank == rank)
            .map((item) => {
              return {
                label: item?.shift_code,
                value: item?.shift_code,
                shift_code: item?.shift_code,
                shift_time_from: item?.shift_time_from,
                shift_time_to: item?.shift_time_to,
              };
            })
        );
      } else {
        const uniqueNames3 = {};
        const newTempShiftArrayData = contractData?.reduce((acc, obj) => {
          if (!uniqueNames3[obj.so_rank]) {
            uniqueNames3[obj.so_rank] = true;
            acc.push(obj);
          }
          return acc;
        }, []);

        console.log("newTempShiftArrayData", newTempShiftArrayData);

        setShiftArray(
          contractData
            ?.filter((x) => x.so_rank == rank)
            .map((item) => {
              return {
                label: item?.shift_code,
                value: item?.shift_code,
                shift_code: item?.shift_code,
                shift_time_from: item?.shift_time_from,
                shift_time_to: item?.shift_time_to,
              };
            })
        );
      }
    }
  }, [rank, scheduleData, contractData]);

  useEffect(() => {
    if (scheduleData.length > 0) {
      setShift(scheduleData[0]?.shift_code);
    } else {
      if (shiftArray.length > 0 && shiftArray.length === 1) {
        setShift(scheduleData[0]?.shift_code);
      } else {
        setShift(rankArray[0]?.shift_code);
      }
    }
  }, [shiftArray]);

  // useEffect(() => {
  //   if (scheduleData.length > 0) {
  //     setPost(scheduleData[0]?.post_code);
  //     setShift(scheduleData[0]?.shift_code);
  //     setRank(scheduleData[0]?.so_rank);
  //     setStartShiftTime( moment.utc(scheduleData[0]?.shift_time_from).format("HH:mm") );
  //     setEndShiftTime( moment.utc(scheduleData[0]?.shift_time_to).format("HH:mm") );
  //     setStartShiftTimeTwo(moment.utc(scheduleData[0]?.shift_time_from));
  //     setEndShiftTimeTwo(moment.utc(scheduleData[0]?.shift_time_to));
  //   }
  // }, [employee, scheduleData]);

  useEffect(() => {
    if (newShiftArrayDependOnRank?.length > 0) {
      const uniqueNames = {};
      const temp = newShiftArrayDependOnRank?.reduce((acc, obj) => {
        if (!uniqueNames[obj.shift_code]) {
          uniqueNames[obj.shift_code] = true;
          acc.push(obj);
        }
        return acc;
      }, []);
      setShiftArray(
        temp?.map((item) => {
          return {
            label: item?.shift_code,
            value: item?.shift_code,
            shift_code: item?.shift_code,
            shift_time_from: item?.shift_time_from,
            shift_time_to: item?.shift_time_to,
          };
        })
      );
    }
  }, [rank]);

  // Disable dates that are not in the current month
  const disabledDate = (date) => {
    const currentDate = new Date();
    return (
      date.getMonth() !== currentDate.getMonth() ||
      date.getFullYear() !== currentDate.getFullYear()
    );
  };

  const ErrorModal = () => {
    return (
      <Modal
        className="modalFormSec attendApprlModalSmall modelConfirmCreate"
        backdrop="static"
        keyboard={false}
        open={openModal}
        onClick={() => setOpenModal(false)}
      >
        <Modal.Body>
          <img
            src="../../../../images/icons/attendance/info_icon.svg"
            alt="export_icon"
            width={40}
            height={40}
          />
          <p>{errorMsg}</p>
          <div>
            <button onClick={() => setOpenModal(false)} className="btn2 bg-red">
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <Modal
      backdrop="static"
      className="modalFormSec attendApprDtlMdl attendApprDtlMdl2 attendanceDetailModal reportImgDetail qrCodeViewSec new-width"
      size="md"
      open={AddNewModal}
      onClose={() => {
        setAddNewModal(false);
        setEmpData();
        setRegularHrs();
        setOtHrs();
        setJustification();
        setDateAltered(false);
        setValidationMsg("");
        handleClear();
      }}
    >
      <ErrorModal />
      <Modal.Header>
        <Modal.Title>Add Attendance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container row">
          {/* loading */}
          {getDataLoading || ManualSetLoading ? (
            <div
              className="loaderSec"
              style={{ position: "absolute", zIndex: 999 }}
            >
              <div className="loaderBox">
                <ImpulseSpinner backColor="#666" frontColor="#E4002B" />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-md-12">
              <table className="text-left">
                <tbody>
                  <tr>
                    <td>State</td>
                    <td>:</td>
                    <td>
                      <SelectPicker
                        data={
                          stateArray
                            ? stateArray?.map((item) => {
                                return {
                                  label: item.state?.toUpperCase(),
                                  value: item.state,
                                };
                              })
                            : []
                        }
                        style={{ width: 224 }}
                        placeholder="Select State"
                        value={state}
                        onChange={(val) => {
                          setState(val);
                          setCity("");
                          setLocation("");
                          setActionType("City");
                        }}
                        // disabled={
                        //   user?.sel_cust === "A" && customerId?.length === 0
                        //     ? true
                        //     : false
                        // }
                        onMouseOver={() => {
                          setToolState("state");
                        }}
                        onMouseOut={() => {
                          setToolState("");
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>:</td>
                    <td>
                      {" "}
                      <SelectPicker
                        data={
                          cityArray
                            ? cityArray?.map((item) => {
                                return {
                                  label: item.city?.toUpperCase(),
                                  value: item.city,
                                };
                              })
                            : []
                        }
                        style={{ width: 224 }}
                        placeholder="Select City"
                        value={city}
                        onChange={(val) => {
                          setCity(val);
                          setLocation("");
                          setActionType("Location");
                        }}
                        // disabled={
                        //   user?.sel_cust === "A" && state?.length > 0
                        //     ? false
                        //     : true
                        // }
                        onMouseOver={() => {
                          setToolState("city");
                        }}
                        onMouseOut={() => {
                          setToolState("");
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>:</td>
                    <td>
                      {" "}
                      <SelectPicker
                        data={
                          locationArray
                            ? locationArray?.map((item) => {
                                return {
                                  label: `${item.assignment_code?.toUpperCase()},
                                   ${item.assignment_address}`,
                                  value: item.assignment_id,
                                };
                              })
                            : []
                        }
                        style={{ width: 224 }}
                        placeholder="Select location"
                        value={location}
                        onChange={(val) => {
                          let newVal = locationArray?.find(
                            (item) => item.assignment_id === val
                          );
                          setLocation(val);
                          setAssignments(newVal);
                          setEmployee("");
                          setActionType("Employee");
                        }}
                        // disabled={
                        //   user?.sel_cust === "A" && city?.length > 0
                        //     ? false
                        //     : true
                        // }
                        onMouseOver={() => {
                          setToolState("Location");
                        }}
                        onMouseOut={() => {
                          setToolState("");
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Duty Date</td>
                    <td>:</td>
                    <td>
                      {" "}
                      <DatePicker
                        disabledDate={disabledDate}
                        onChange={(i) => setDutyDate(i)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Employee</td>
                    <td>:</td>
                    <td>
                      <SelectPicker
                        data={
                          employeeArray
                            ? employeeArray?.map((item) => {
                                return {
                                  label: `${item.EmpCode} - ${item.employee_name}`,
                                  value: item.EmpCode,
                                };
                              })
                            : []
                        }
                        style={{ width: 224 }}
                        placeholder="Select Employee"
                        value={employee}
                        onChange={(val) => {
                          let newVal = employeeArray?.find(
                            (item) => item.EmpCode === val
                          );

                          setActionType("Post");
                          setEmployee(val);
                          setEmployeeData(newVal);
                        }}
                        // disabled={
                        //   user?.sel_cust === "A" && city?.length > 0
                        //     ? false
                        //     : true
                        // }
                        // onMouseOver={() => {
                        //   setToolState("employee");
                        // }}
                        // onMouseOut={() => {
                        //   setToolState("");
                        // }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Post</td>
                    <td>:</td>
                    <td>
                      <SelectPicker
                        data={
                          postArray
                            ? postArray?.map((item) => {
                                return {
                                  label: item.post_code,
                                  value: item.post_code,
                                };
                              })
                            : []
                        }
                        style={{ width: 224 }}
                        placeholder="Select Post"
                        value={post}
                        onChange={(val) => {
                          let newVal = postArray?.find(
                            (item) => item.post_code === val
                          );
                          console.log("new val", newVal, val);
                          setPost(val);
                          setNewPostData(newVal);
                        }}
                        onMouseOver={() => {
                          setToolState("Post");
                        }}
                        onMouseOut={() => {
                          setToolState("");
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Rank</td>
                    <td>:</td>
                    <td>
                      <SelectPicker
                        data={
                          rankArray
                            ? rankArray?.map((item) => {
                                return {
                                  label: `${item.label}`,
                                  value: item.value,
                                };
                              })
                            : []
                        }
                        style={{ width: 224 }}
                        placeholder="Select Rank"
                        value={rank}
                        onChange={(val) => {
                          let newVal = rankArray?.find(
                            (item) => item.value === val
                          );
                          setRank(val);

                          setNewShiftArrayDependOnRank(newVal);
                        }}
                        // disabled={
                        //   user?.sel_cust === "A" && city?.length > 0
                        //     ? false
                        //     : true
                        // }
                        onMouseOver={() => {
                          setToolState("Post");
                        }}
                        onMouseOut={() => {
                          setToolState("");
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Shift</td>
                    <td>:</td>
                    <td>
                      <SelectPicker
                        data={
                          shiftArray
                            ? shiftArray?.map((item) => {
                                return {
                                  label: `${item.label}  (${moment
                                    .utc(item?.shift_time_from)
                                    .format("HH:mm")} - ${moment
                                    .utc(item?.shift_time_to)
                                    .format("HH:mm")})`,
                                  value: item.value,
                                };
                              })
                            : []
                        }
                        style={{ width: 224 }}
                        placeholder="Select Shift"
                        value={shift}
                        onChange={(val) => {
                          let newVal = shiftArray?.find(
                            (item) => item.shift_code == val
                          );

                          setStartShiftTime(
                            moment.utc(newVal?.shift_time_from).format("HH:mm")
                          );
                          setEndShiftTime(
                            moment.utc(newVal?.shift_time_to).format("HH:mm")
                          );
                          setStartShiftTimeTwo(
                            moment.utc(newVal?.shift_time_from)
                          );
                          setEndShiftTimeTwo(moment.utc(newVal?.shift_time_to));

                          setShift(val);
                        }}
                        onMouseOver={() => {
                          setToolState("Post");
                        }}
                        onMouseOut={() => {
                          setToolState("");
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Shift Start Time</td>
                    <td>:</td>
                    {/* <td>{`${empData?.shiftstart.split(":")[0]}:${
                      empData?.shiftstart.split(":")[1]
                    }`}</td> */}
                    <td>
                      {shift !== "" && shift !== null && post !== null
                        ? startShiftTime
                        : "00:00"}
                    </td>
                  </tr>
                  <tr>
                    <td>Shift End Time </td>
                    <td>:</td>
                    {/* <td>{`${empData?.shiftend.split(":")[0]}:${
                      empData?.shiftend.split(":")[1]
                    }`}</td> */}
                    <td>
                      {shift !== "" && shift !== null && post !== null
                        ? endShiftTime
                        : "00:00"}
                    </td>
                  </tr>

                  <tr>
                    <td>In Time</td>
                    <td>:</td>
                    <td>
                      <DatePicker
                        ranges={[]}
                        placement="auto"
                        disabledHours={(h) => {
                          setHour(h);
                        }}
                        disabledMinutes={(minutes) => {
                          return minutes > 30;
                        }}
                        hideMinutes={(minute) => minute % 30 !== 0}
                        onChange={(val) => {
                          let newData = moment(val).format("HH");

                          setDateAltered(true);
                          setInTime(val);
                        }}
                        format="HH:mm"
                        cleanable={true}
                        editable={true}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td> Out Time</td>
                    <td>:</td>

                    <td>
                      <DatePicker
                        ranges={[]}
                        placement="auto"
                        disabledHours={(h) => {
                          setHour(h);
                        }}
                        disabledMinutes={(minutes) => {
                          return minutes > 30;
                        }}
                        hideMinutes={(minute) => minute % 30 !== 0}
                        onChange={(val) => {
                          setDateAltered(true);
                          setOutTime(val);
                        }}
                        format="HH:mm"
                        cleanable={true}
                        editable={true}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Total Performed Hrs</td>
                    <td>:</td>
                    <td>
                      {inTime == null ||
                      outTime == null ||
                      inTime == undefined ||
                      outTime == undefined
                        ? "00:00"
                        : totalPerfHrs}
                    </td>
                  </tr>

                  <tr>
                    <td> Regular Hrs </td>
                    <td>:</td>
                    {/* <td>{empData?.regularHrs}</td> */}
                    <td>{post !== "" ? regularHrs : "00:00"}</td>
                  </tr>

                  <tr>
                    <td>Ot Hrs</td>
                    <td>:</td>
                    {/* <td>{empData?.ApprovedOut}</td> */}
                    <td>
                      {" "}
                      {inTime == null ||
                      outTime == null ||
                      inTime == undefined ||
                      outTime == undefined
                        ? "00:00"
                        : otHrs}
                    </td>
                  </tr>

                  <tr>
                    <td>Justification</td>
                    <td>:</td>
                    <td>
                      <Input
                        as="textarea"
                        value={justification}
                        onChange={(val) => {
                          setValidationMsg("");
                          setJustification(val);
                        }}
                        rows={3}
                        placeholder="Write Your justifications Here"
                      />
                      <span className="text-danger">{validationMsg}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="modalBtn">
                <button
                  className="btn2 btnBlack"
                  onClick={() => {
                    setAddNewModal(false);

                    // setDateAltered(false);
                  }}
                >
                  Cancel
                </button>

                <button
                  className="btn2 bg-red"
                  onClick={() => handleManualSet()}
                >
                  ValiDate
                </button>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(AttendanceDetailsModalAddNew);

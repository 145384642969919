import { combineReducers } from "redux";
import { userReducer } from "./user-reducer";
import { dateReducer } from "./date-reducer";
import {incidentReducer} from './incident-reducer'

const rootReducer = combineReducers({
  user: userReducer,
  date: dateReducer,
  incident:incidentReducer,
});

export default rootReducer;

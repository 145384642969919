import React, { useState } from "react";
import { isAfter } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import {
  Checkbox,
  CheckPicker,
  DateRangePicker,
  SelectPicker,
  Tooltip,
} from "rsuite";
import moment from "moment";
import { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";

const ControlFilter = ({ period = false }) => {
  const dispatch = useDispatch();
  const [customerId, setCustomerId] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [fromdate, setFromDate] = useState(
    moment().subtract(1, "d").format("YYYY-MM-DD")
  );
  const [todate, setToDate] = useState(
    moment().subtract(1, "d").format("YYYY-MM-DD")
  );
  const [curDate, setCurDate] = useState([
    new Date(moment().subtract(1, "d")),
    new Date(moment().subtract(1, "d")),
  ]);
  const [periodDuration, setPeriodDuration] = useState("");
  const [toolState, setToolState] = useState("");
  // const user = useSelector((state) => state.user);
  const { user } = useAuth();
  const reduxDate = useSelector((state) => state.date);

  const [customerArray, setCustomerArray] = useState([]);
  const [stateArray, setStateArray] = useState([]);
  const [cityArray, setCityArray] = useState([]);
  const [assignmentsArray, setAssginmentsArray] = useState([]);

  useEffect(() => {
    if (reduxDate) {
      setCustomerId(reduxDate.customerId);
      setFromDate(reduxDate.fromdate);
      setToDate(reduxDate.todate);
      setAssignments(reduxDate.assignments);
      setState(reduxDate.state);
      setCity(reduxDate.city);
      setCurDate(reduxDate.curDate);
    }
  }, [reduxDate]);

  useEffect(() => {
    if (user && customerId.length === 0) {
      let customer =
        user?.sel_cust === "A" && user?.customersList
          ? user?.customersList.map((item) => {
              return item.value;
            })
          : [];

      let state = [
        ...new Set(
          user?.userdetails_v1
            ?.filter((item) => item.state !== null)
            .map((item) => item.state)
        ),
      ];

      let city = [
        ...new Set(
          user?.userdetails_v1
            ?.filter((item) => item.city !== null)
            .map((item) => item.city)
        ),
      ];

      let assignments = [
        ...new Set(
          user?.userdetails_v1
            ?.filter((item) => item.assignment_id !== null)
            .map((item) => item.assignment_id)
        ),
      ];
      setCustomerArray(customer);
      setStateArray(state);
      setCityArray(city);
      setAssginmentsArray(assignments);
    }
  }, [user, customerId]);

  useEffect(() => {
    if (customerId.length > 0) {
      let state = [
        ...new Set(
          user?.userdetails_v1
            ?.filter(
              (item) =>
                item.state !== null && customerId.includes(item.customer_id)
            )
            .map((item) => item.state)
        ),
      ];

      let city = [
        ...new Set(
          user?.userdetails_v1
            ?.filter(
              (item) =>
                item.city !== null && customerId.includes(item.customer_id)
            )
            .map((item) => item.city)
        ),
      ];

      let assignments = [
        ...new Set(
          user?.userdetails_v1
            ?.filter(
              (item) =>
                item.assignment_id !== null &&
                customerId.includes(item.customer_id)
            )
            .map((item) => item.assignment_id)
        ),
      ];
      setStateArray(state);
      setCityArray(city);
      setAssginmentsArray(assignments);
    }
  }, [customerId]);

  useEffect(() => {
    if (state.length > 0) {
      let city = [
        ...new Set(
          user?.userdetails_v1
            ?.filter(
              (item) =>
                item.city !== null &&
                state.includes(item.state) &&
                customerId.includes(item.customer_id)
            )
            .map((item) => item.city)
        ),
      ];

      let assignments = [
        ...new Set(
          user?.userdetails_v1
            ?.filter(
              (item) =>
                item.assignment_id !== null &&
                city.includes(item.city) &&
                customerId.includes(item.customer_id)
            )
            .map((item) => item.assignment_id)
        ),
      ];

      setCityArray(city);
      setAssginmentsArray(assignments);
    }
  }, [state]);

  useEffect(() => {
    if (city.length > 0) {
      let assignments = [
        ...new Set(
          user?.userdetails_v1
            ?.filter(
              (item) =>
                item.assignment_id !== null &&
                city.includes(item.city) &&
                customerId.includes(item.customer_id)
            )
            .map((item) => item.assignment_id)
        ),
      ];
      setAssginmentsArray(assignments);
    }
  }, [city]);

  useEffect(() => {
    if (!reduxDate) {
      let customer =
        user?.sel_cust == "A" && user?.customersList
          ? user?.customersList.map((item) => {
              return item.value;
            })
          : [];

      let state = [
        ...new Set(
          user?.userdetails_v1
            ?.filter((item) => item.state !== null)
            .map((item) => item.state)
        ),
      ];

      let city = [
        ...new Set(
          user?.userdetails_v1
            ?.filter((item) => item.city !== null)
            .map((item) => item.city)
        ),
      ];

      let assignments = [
        ...new Set(
          user?.userdetails_v1
            ?.filter((item) => item.assignment_id !== null)
            .map((item) => item.assignment_id)
        ),
      ];

      setCustomerId(customer);
      setState(state);
      setCity(city);
      setAssignments(assignments);
    }
  }, [reduxDate]);

  const uniqueCustomer = user?.customersList
    ?.map((item) => {
      return {
        customer_id: item.value,
        label: item.label,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const uniqueState = [
    ...new Map(
      user?.userdetails_v1
        ?.filter(
          (item) => item.state != null && customerId.includes(item.customer_id)
        )
        .map((item) => [item.state.trim(), item])
    ).values(),
  ].sort((a, b) => a.state.localeCompare(b.state));

  const uniqueCity = [
    ...new Map(
      user?.userdetails_v1
        ?.filter(
          (item) =>
            item.city != null &&
            customerId.includes(item.customer_id) &&
            state.includes(item.state)
        )
        .map((item) => [item.city.trim(), item])
    ).values(),
  ].sort((a, b) => a.city.localeCompare(b.city));

  const uniqueAssignments = [
    ...new Map(
      user?.userdetails_v1
        ?.filter(
          (item) =>
            item.assignment_id != null &&
            customerId.includes(item.customer_id) &&
            city.includes(item.city)
        )
        .map((item) => [item.assignment_id, item])
    ).values(),
  ].sort((a, b) => a.assignment_code.localeCompare(b.assignment_code));

  const handleSubmit = () => {
    dispatch({
      type: "SET_DATE",
      payload: {
        customerId: customerId.length === 0 ? customerArray : customerId,
        fromdate: fromdate,
        todate: todate,
        curDate: curDate,
        state:
          user?.sel_cust === "A" && state.length === 0 ? stateArray : state,
        city: user?.sel_cust === "A" && city.length === 0 ? cityArray : city,
        assignments:
          user?.sel_cust === "A" && assignments.length === 0
            ? assignmentsArray
            : assignments,
      },
    });
  };

  const handleCheckAllCustomer = (value, checked) => {
    setCustomerId(checked ? customerArray : []);
    setState([]);
    setCity([]);
    setAssignments([]);
  };

  const handleCheckAllStates = (value, checked) => {
    setState(checked ? stateArray : []);
    setCity([]);
    setAssignments([]);
  };

  const handleCheckAllCity = (value, checked) => {
    setCity(checked ? cityArray : []);
    setAssignments([]);
  };

  const handleCheckAllAssignments = (value, checked) => {
    setAssignments(checked ? assignmentsArray : []);
  };

  return (
    <div className="topBarStrip">
      <ul className="topBarStripCol6">
        <li>
          <CheckPicker
            data={
              uniqueCustomer
                ? uniqueCustomer.map((item) => {
                    return {
                      label: item.label,
                      value: item.customer_id,
                    };
                  })
                : []
            }
            style={{ width: 224 }}
            placeholder="Select Customer"
            value={customerId}
            onChange={(val) => {
              if (val == null) {
                dispatch({
                  type: "SET_DATE",
                  payload: null,
                });
              }
              setCustomerId(val);
              setState([]);
              setCity([]);
              setAssignments([]);
            }}
            renderExtraFooter={() => (
              <div className="multiSelectAllFtr">
                {customerArray.length > 0 && (
                  <Checkbox
                    inline
                    indeterminate={
                      customerId.length > 0 &&
                      customerId.length < customerArray.length
                    }
                    checked={customerId.length === customerArray.length}
                    onChange={handleCheckAllCustomer}
                  >
                    Select All Customer
                  </Checkbox>
                )}
              </div>
            )}
            renderValue={(value, items) => {
              return (
                <span>
                  <span style={{ color: "#575757" }}>
                    <i className="rs-icon rs-icon-user" />
                    {customerId.length === customerArray.length ? (
                      <span className="text-danger">All Customer Selected</span>
                    ) : (
                      ""
                    )}{" "}
                  </span>{" "}
                  {customerId.length !== customerArray.length &&
                    items.map((item) => {
                      return item.label;
                    })}
                </span>
              );
            }}
          />
        </li>
        <li>
          {user?.sel_cust === "A" &&
          customerId.length === 0 &&
          toolState === "state" ? (
            <Tooltip className="filterTooltip" visible>
              {"Please Select Customer"}
            </Tooltip>
          ) : (
            <></>
          )}
          <CheckPicker
            data={
              uniqueState
                ? uniqueState.map((item) => {
                    return {
                      label: item.state.toUpperCase(),
                      value: item.state,
                    };
                  })
                : []
            }
            style={{ width: 224 }}
            placeholder="Select State"
            value={state}
            onChange={(val) => {
              setState(val);
              setCity([]);
              setAssignments([]);
            }}
            renderExtraFooter={() => (
              <div className="multiSelectAllFtr">
                {(() => {
                  if (
                    (customerId.length > 0 && stateArray.length > 0) ||
                    user.sel_cust == null
                  ) {
                    return (
                      <Checkbox
                        inline
                        indeterminate={
                          state.length > 0 && state.length < stateArray.length
                        }
                        checked={state.length === stateArray.length}
                        onChange={handleCheckAllStates}
                      >
                        Select All States
                      </Checkbox>
                    );
                  } else {
                    return <></>;
                  }
                })()}
              </div>
            )}
            renderValue={(value, items) => {
              return (
                <span>
                  <span style={{ color: "#575757" }}>
                    <i className="rs-icon rs-icon-user" />
                    {user?.sel_cust === "A" &&
                    state.length === stateArray.length ? (
                      <span className="text-danger">All States Selected</span>
                    ) : (
                      <></>
                    )}{" "}
                  </span>{" "}
                  {state.length !== stateArray.length &&
                    items.map((item) => {
                      return item.label;
                    })}
                </span>
              );
            }}
            disabled={
              user?.sel_cust === "A" && customerId.length === 0 ? true : false
            }
            onMouseOver={() => {
              setToolState("state");
            }}
            onMouseOut={() => {
              setToolState("");
            }}
          />
        </li>
        <li>
          {state.length === 0 && toolState === "city" ? (
            <Tooltip className="filterTooltip" visible>
              {"Please Select State"}
            </Tooltip>
          ) : (
            <></>
          )}
          <CheckPicker
            data={
              uniqueCity
                ? uniqueCity.map((item) => {
                    return {
                      label: item.city.toUpperCase(),
                      value: item.city,
                    };
                  })
                : []
            }
            style={{ width: 224 }}
            placeholder="Select City"
            value={city}
            onChange={(val) => {
              setCity(val);
              setAssignments([]);
            }}
            renderExtraFooter={() => (
              <div className="multiSelectAllFtr">
                {state.length > 0 && (
                  <Checkbox
                    inline
                    indeterminate={
                      city.length > 0 && city.length < cityArray.length
                    }
                    checked={city.length === cityArray.length}
                    onChange={handleCheckAllCity}
                  >
                    Select All Cities
                  </Checkbox>
                )}
              </div>
            )}
            renderValue={(value, items) => {
              return (
                <span>
                  <span style={{ color: "#575757" }}>
                    <i className="rs-icon rs-icon-user" />
                    {city.length === cityArray.length ? (
                      <span className="text-danger">All Cities Selected</span>
                    ) : (
                      <></>
                    )}{" "}
                  </span>{" "}
                  {city.length !== cityArray.length &&
                    items.map((item) => {
                      return item.label;
                    })}
                </span>
              );
            }}
            disabled={user?.sel_cust === "A" && state.length > 0 ? false : true}
            onMouseOver={() => {
              setToolState("city");
            }}
            onMouseOut={() => {
              setToolState("");
            }}
          />
        </li>
        <li>
          {city.length === 0 && toolState === "assignments" ? (
            <Tooltip className="filterTooltip" visible>
              {"Please Select City"}
            </Tooltip>
          ) : (
            <></>
          )}
          <CheckPicker
            data={
              uniqueAssignments
                ? uniqueAssignments.map((item) => {
                    return {
                      label: `${item.assignment_code.toUpperCase()},
                         ${item.assignment_address}`,
                      value: item.assignment_id,
                    };
                  })
                : []
            }
            style={{ width: 224 }}
            placeholder="Select Assignment"
            value={assignments}
            onChange={(val) => {
              setAssignments(val);
            }}
            renderExtraFooter={() => (
              <div className="multiSelectAllFtr">
                {city.length > 0 && (
                  <Checkbox
                    inline
                    indeterminate={
                      assignments.length > 0 &&
                      assignments.length < assignmentsArray.length
                    }
                    checked={assignments.length === assignmentsArray.length}
                    onChange={handleCheckAllAssignments}
                  >
                    Select All Assignments
                  </Checkbox>
                )}
              </div>
            )}
            renderValue={(value, items) => {
              return (
                <span>
                  <span style={{ color: "#575757" }}>
                    <i className="rs-icon rs-icon-user" />
                    {assignments.length === assignmentsArray.length ? (
                      <span className="text-danger">
                        All Assignments Selected
                      </span>
                    ) : (
                      <></>
                    )}{" "}
                  </span>{" "}
                  {assignments.length !== assignmentsArray.length &&
                    items.map((item) => {
                      return item.label;
                    })}
                </span>
              );
            }}
            disabled={user?.sel_cust === "A" && city.length > 0 ? false : true}
            onMouseOver={() => {
              setToolState("assignments");
            }}
            onMouseOut={() => {
              setToolState("");
            }}
          />
        </li>

        {period === true && (
          <li>
            <SelectPicker
              data={[
                { value: "month", label: "Month" },
                { value: "week", label: "Week" },
              ]}
              style={{ width: 224 }}
              placeholder="Select Period"
              value={periodDuration}
              onChange={setPeriodDuration}
            />
          </li>
        )}
        <li>
          <DateRangePicker
            showOneCalendar
            cleanable={false}
            ranges={[]}
            disabledDate={(date) => isAfter(date, new Date())}
            placeholder="Select Date Range"
            value={curDate}
            onChange={(val) => {
              if (val == null) {
                setFromDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
                setToDate(moment().subtract(1, "d").format("YYYY-MM-DD"));
              } else {
                setFromDate(moment(val[0]).format("YYYY-MM-DD"));
                setToDate(moment(val[1]).format("YYYY-MM-DD"));
              }
              setCurDate(val);
            }}
          />
        </li>
        <li>
          <button
            className="btn2"
            onClick={() => {
              handleSubmit();
            }}
            type="button"

            // disabled={user?.sel_cust == "A" && !customerId ? true : false}
          >
            Submit
          </button>
        </li>
      </ul>
    </div>
  );
};

export default React.memo(ControlFilter);

import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Modal, CheckPicker, Badge, SelectPicker } from "rsuite";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useEffect } from "react";
import api from "../lib/api";
import cogoToast from "cogo-toast";
import { ImpulseSpinner } from "react-spinners-kit";
import { useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const GuestHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [open2, setOpen2] = useState(false);
  const [hitOption, setHitOption] = useState(false);
  const { user, logout } = useAuth();
  const accessDetails = JSON.parse(user?.accessDetails);

  const handleLogOut = () => {
    logout();
    Cookies.remove("myToken");
    dispatch({
      type: "SET_DATE",
      payload: null,
    });
    navigate("/login");
  };

  useEffect(() => {
    if (user?.sel_cust === "A" && user?.userdetails_v1 == null) {
      handleOpen();
    }
  }, [user]);



  return (
    <div className="guestHeader">
      <div className="container-fluid">
        {user?.userdetails_v1 == null && (
          <CustomerModal open={open} hitOption={hitOption} />
        )}
        <ChangeCustomerModal
          open2={open2}
          setOpen2={setOpen2}
          hitOption={hitOption}
        />
        <div className="row">
          <div className="col-lg-4 col-md-3 col-6">
            <div className="guestLogo">
              <Link to={"/"}>
                <img
                  src="../../../../images/g4s_logo.png"
                  width="100%"
                  height="100%"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
              {user?.sel_cust === "A" ? (
                <span>{user?.customerGroupName}</span>
              ) : (
                <span>{user?.username}</span>
              )}
            </div>
          </div>
          <div className="col-lg-5 col-md-6 xs_hidden">
            <div className="topMenuSec">
              <ul>
                <li className={path === "/" ? "headerActive" : ""}>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li className={path.includes("reports") ? "headerActive" : ""}>
                  <a>Reports</a>
                  <ul className="topMenuDropdown">
                    {accessDetails.find(
                      (item) => item.option_name === "Reports_Contract_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"reports/attendance/contracted"}>
                          Contracted
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) => item.option_name === "Reports_Performed_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/attendance/performed"}>
                          Performed
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) =>
                        item.option_name === "Reports_AdditionalPerformed_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/attendance/additional-performed"}>
                          Additional Performed
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) => item.option_name === "Reports_Unperformed_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/attendance/unperformed"}>
                          Unperformed
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) =>
                        item.option_name === "Reports_AttendanceApproval_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/attendance-approval"}>
                          Attendance Approval
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) =>
                        item.option_name === "Reports_AttendanceApprovalV1_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/attendance-approval-v1"}>
                          Attendance Approval
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) =>
                        item.option_name === "Reports_LocationException_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/location-exception"}>
                          Location Exception
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) => item.option_name === "Reports_Manpower_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/manpower-overview"}>
                          Manpower Overview
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) => item.option_name === "Reports_Inspection_Get"
                    ) !== undefined ? (
                      <li>
                        <li>
                          <Link to={"/reports/inspection"}>Inspection</Link>
                        </li>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) =>
                        item.option_name === "Reports_IncidentReported_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/incident-reported"}>
                          Incidents Reported
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                    
                    {accessDetails.find(
                      (item) =>
                        item.option_name ===
                        "Reports_SitePatrolling_TourReportsSubmit"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/site-patrolling"}>
                          Site Patroling
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </li>
                {accessDetails.find(
                  (item) => item.option_name === "ContactUs"
                ) !== undefined ? (
                  <li
                    className={
                      path.includes("contact-us") ? "headerActive" : ""
                    }
                  >
                    <Link to={"/contact-us"}>Contact Us</Link>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6">
            <div className="settingDropdown">
              <button className="dropbtn">
                <i className="far fa-user"></i>
                <span>{user && user.emailid}</span>
              </button>
              <ul className="dropdown-content">
                {user?.sel_cust === "A" && (
                  <li
                    onClick={() => {
                      setHitOption(true);
                      setOpen2(true);
                    }}
                  >
                    <a>
                      <i className="fa fa-user" aria-hidden="true"></i>
                      Change Customer
                    </a>
                  </li>
                )}

                <li onClick={handleLogOut}>
                  <a>
                    <i className="fas fa-sign-out-alt"></i>Log Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 desktopall_hidden">
            <div className="topMenuSec">
              <ul>
                <li>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li>
                  <a>Reports</a>
                  <ul className="topMenuDropdown">
                    {accessDetails.find(
                      (item) => item.option_name === "Reports_Contract_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"reports/attendance/contracted"}>
                          Contracted
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) => item.option_name === "Reports_Performed_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/attendance/performed"}>
                          Performed
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) =>
                        item.option_name === "Reports_AdditionalPerformed_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/attendance/additional-performed"}>
                          Additional Performed
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) => item.option_name === "Reports_Unperformed_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/attendance/unperformed"}>
                          Unperformed
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) =>
                        item.option_name === "Reports_AttendanceApproval_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/attendance-approval"}>
                          Attendance Approval
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) =>
                        item.option_name === "Reports_LocationException_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/location-exception"}>
                          Location Exception
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) => item.option_name === "Reports_Manpower_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/manpower-overview"}>
                          Manpower Overview
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) => item.option_name === "Reports_Inspection_Get"
                    ) !== undefined ? (
                      <li>
                        <li>
                          <Link to={"/reports/inspection"}>Inspection</Link>
                        </li>
                      </li>
                    ) : (
                      <></>
                    )}

                    {accessDetails.find(
                      (item) =>
                        item.option_name === "Reports_IncidentReported_Get"
                    ) !== undefined ? (
                      <li>
                        <Link to={"/reports/incident-reported"}>
                          Incidents Reported
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {/* <li>
                      <Link to={"/reports/site-patrolling"}>
                        Site Patroling
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <Link to={"/contact-us"}>Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomerModal = ({ open, hitOption }) => {
  const dispatch = useDispatch();
  const { user, login } = useAuth();
  const [customerGroupList, setCustomerGroupList] = useState([]);
  const [customerGroupId, setCustomerGroupId] = useState(-1);
  const [customersList, setCustomersList] = useState([]);
  const [customerIdList, setCustomerIdList] = useState([]);

  const footerStyles = {
    padding: "10px 2px",
    borderTop: "1px solid #e5e5e5",
    maxHeight: "100px",
    overflowY: "auto",
  };

  const { mutate: getUserdetails, isLoading: assignmentDataLoading } =
    useMutation(
      (data) => api.post("/CustomerListGetAll_V1/GetAssignmentList", data),
      {
        onSuccess: (res) => {
          if (localStorage.getItem("userData")) {
            let local = JSON.parse(localStorage.getItem("userData"));
            local.userdetails_v1 = res.data.assignmentlist;
            customerGroupList
              .filter((item) => customerGroupId === item.group_id)
              .map((item) => {
                local.customerGroupId = item.group_id;
                local.customerGroupName = item.group_customer_name;
              });

            local.customersList = customersList
              .filter((item) => customerIdList.includes(item.customer_id))
              .map((item) => {
                return {
                  value: item.customer_id,
                  label: item.customer_name,
                };
              });
            localStorage.removeItem("userData");
            localStorage.setItem("userData", JSON.stringify(local));
            login(local);
            dispatch({
              type: "SET_DATE",
              payload: null,
            });
          }
          // handleClose();
        },
      }
    );

  const { mutate: getAllCustomers } = useMutation(
    (data) => api.post("/CustomerListGetAll_V1", data),
    {
      onSuccess: (res) => {
        setCustomersList(res.data.customerlist);
        setCustomerIdList(
          res.data.customerlist.map((item) => {
            return item.customer_id;
          })
        );
      },
    }
  );

  const { mutate: getAllGroup, isLoading: customerDataLoading } = useMutation(
    (data) => api.post("/CustomerListGetAll_V1/GetCustomerGroups", data),
    {
      onSuccess: (res) => {
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
        } else {
          setCustomerGroupList(res.data.customergrouplist);
        }
      },
    }
  );

  useEffect(() => {
    if (customerGroupId > -1) {
      getAllCustomers({
        userid: user?.userid,
        customerGroupid: customerGroupId,
      });
    }
  }, [customerGroupId]);

  const handleSubmit = () => {
    getUserdetails({
      userid: user?.userid,
      customers: customerIdList.join(":"),
    });
  };

  //DROPDOWN LOADING
  const renderMenu = (menu) => {
    if (customerDataLoading) {
      return (
        <div className="mx-auto p-2">
          <ImpulseSpinner backColor="#666" frontColor="#E4002B" />
        </div>
      );
    }
    return menu;
  };

  return (
    <Modal
      className="modalFormSec modalCustomerSec"
      size="xs"
      open={open}
      backdrop={"static"}
    >
      <Modal.Header closeButton={hitOption ? true : false}>
        <Modal.Title>Select Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <label>Customer Group</label>
              <SelectPicker
                data={
                  customerGroupList
                    ? customerGroupList.map((item) => {
                        return {
                          label: `(${item.group_customer_code}) -${item.group_customer_name}`,
                          value: item.group_id,
                        };
                      })
                    : []
                }
                placeholder="Select Customer"
                value={customerGroupId}
                onChange={setCustomerGroupId}
                onSearch={(val) => {
                  if (val.length > 2) {
                    getAllGroup({
                      userid: user?.userid,
                      searchtext: val,
                    });
                  }
                }}
                renderMenu={renderMenu}
                renderExtraFooter={() => (
                  <div
                    className="selectBoxSelectedListSec"
                    style={footerStyles}
                  >
                    <ul>
                      {user?.customerGroup?.map((item, i) => (
                        <li key={i}>
                          <button style={{ cursor: "default" }}>
                            {item.label}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              />
            </div>
            <div className="col-md-6">
              <label>Customer List</label>
              <CheckPicker
                data={
                  customersList
                    ? customersList.map((item) => {
                        return {
                          label: `(${item.customer_code}) -${item.customer_name}`,
                          value: item.customer_id,
                        };
                      })
                    : []
                }
                placeholder="Select Customer"
                value={customerIdList}
                onChange={setCustomerIdList}
                renderMenu={renderMenu}
                renderExtraFooter={() => (
                  <div
                    className="selectBoxSelectedListSec"
                    style={footerStyles}
                  >
                    <ul>
                      {user?.customersList?.map((item, i) => (
                        <li key={i}>
                          {/* <Badge
                            content={"X"}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteChange(item.value)}
                          > */}
                          <button style={{ cursor: "default" }}>
                            {item.label}
                          </button>
                          {/* </Badge> */}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              />
            </div>
            <div className="col-md-12">
              <div className="btnCenter pdt_15">
                <button
                  disabled={
                    customerGroupId?.length == 0 || customerIdList?.length == 0
                  }
                  onClick={handleSubmit}
                  className="btn btn-danger"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          {assignmentDataLoading && (
            <div className="mx-auto pt-4 d-flex justify-content-center">
              <ImpulseSpinner backColor="#666" frontColor="#E4002B" />
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const ChangeCustomerModal = ({ open2, hitOption, setOpen2 }) => {
  const dispatch = useDispatch();
  const { user, login } = useAuth();
  const [customerGroupList, setCustomerGroupList] = useState([]);
  const [customerGroupId, setCustomerGroupId] = useState(-1);
  const [customersList, setCustomersList] = useState([]);
  const [customerIdList, setCustomerIdList] = useState([]);



  const footerStyles = {
    padding: "10px 2px",
    borderTop: "1px solid #e5e5e5",
    maxHeight: "100px",
    overflowY: "auto",
  };

  const { mutate: getUserdetails, isLoading: assignmentDataLoading } =
    useMutation(
      (data) => api.post("/CustomerListGetAll_V1/GetAssignmentList", data),
      {
        onSuccess: (res) => {
          if (localStorage.getItem("userData")) {
            let local = JSON.parse(localStorage.getItem("userData"));
            local.userdetails_v1 = res.data.assignmentlist;
            customerGroupList
              .filter((item) => customerGroupId === item.group_id)
              .map((item) => {
                local.customerGroupId = item.group_id;
                local.customerGroupName = item.group_customer_name;
              });

            local.customersList = customersList
              .filter((item) => customerIdList.includes(item.customer_id))
              .map((item) => {
                return {
                  value: item.customer_id,
                  label: item.customer_name,
                };
              });
            localStorage.removeItem("userData");
            localStorage.setItem("userData", JSON.stringify(local));
            login(local);
            dispatch({
              type: "SET_DATE",
              payload: null,
            });
          }
          setOpen2(false);
          window.location.reload();
        },
      }
    );

  const { mutate: getAllCustomers } = useMutation(
    (data) => api.post("/CustomerListGetAll_V1", data),
    {
      onSuccess: (res) => {
        setCustomersList(res.data.customerlist);
        setCustomerIdList(
          res.data.customerlist.map((item) => {
            return item.customer_id;
          })
        );
      },
    }
  );

  const { mutate: getAllGroup, isLoading: customerDataLoading } = useMutation(
    (data) => api.post("/CustomerListGetAll_V1/GetCustomerGroups", data),
    {
      onSuccess: (res) => {
        if (res.data.response === "Failed") {
          cogoToast.error(res.data.errorMsg);
        } else {
          setCustomerGroupList(res.data.customergrouplist);
        }
      },
    }
  );

  useEffect(() => {
    if (customerGroupId > -1) {
      getAllCustomers({
        userid: user?.userid,
        customerGroupid: customerGroupId,
      });
    }
  }, [customerGroupId]);

  const handleSubmit = () => {
    getUserdetails({
      userid: user?.userid,
      customers: customerIdList.join(":"),
    });
  };

  //DROPDOWN LOADING
  const renderMenu = (menu) => {
    if (customerDataLoading) {
      return (
        <div className="mx-auto p-2">
          <ImpulseSpinner backColor="#666" frontColor="#E4002B" />
        </div>
      );
    }
    return menu;
  };

  return (
    <Modal
      className="modalFormSec modalCustomerSec"
      size="xs"
      open={open2}
      onClose={() => setOpen2(false)}
      backdrop={"static"}
    >
      <Modal.Header closeButton={hitOption ? true : false}>
        <Modal.Title>Select Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <label>Customer Group</label>
              <SelectPicker
                data={
                  customerGroupList
                    ? customerGroupList.map((item) => {
                        return {
                          label: `(${item.group_customer_code}) -${item.group_customer_name}`,
                          value: item.group_id,
                        };
                      })
                    : []
                }
                placeholder="Select Customer"
                value={customerGroupId}
                onChange={setCustomerGroupId}
                onSearch={(val) => {
                  if (val.length > 2) {
                    getAllGroup({
                      userid: user?.userid,
                      searchtext: val,
                    });
                  }
                }}
                renderMenu={renderMenu}
                renderExtraFooter={() => (
                  <div
                    className="selectBoxSelectedListSec"
                    style={footerStyles}
                  >
                    <ul>
                      {user?.customerGroup?.map((item, i) => (
                        <li key={i}>
                          <button style={{ cursor: "default" }}>
                            {item.label}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              />
            </div>
            <div className="col-md-6">
              <label>Customer List</label>
              <CheckPicker
                data={
                  customersList
                    ? customersList.map((item) => {
                        return {
                          label: `(${item.customer_code}) -${item.customer_name}`,
                          value: item.customer_id,
                        };
                      })
                    : []
                }
                placeholder="Select Customer"
                value={customerIdList}
                onChange={setCustomerIdList}
                renderMenu={renderMenu}
                renderExtraFooter={() => (
                  <div
                    className="selectBoxSelectedListSec"
                    style={footerStyles}
                  >
                    <ul>
                      {user?.customersList?.map((item, i) => (
                        <li key={i}>
                          {/* <Badge
                            content={"X"}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteChange(item.value)}
                          > */}
                          <button style={{ cursor: "default" }}>
                            {item.label}
                          </button>
                          {/* </Badge> */}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              />
            </div>
            <div className="col-md-12">
              <div className="btnCenter pdt_15">
                <button
                  disabled={
                    customerGroupId?.length == 0 || customerIdList?.length == 0
                  }
                  onClick={handleSubmit}
                  className="btn btn-danger"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          {assignmentDataLoading && (
            <div className="mx-auto pt-4 d-flex justify-content-center">
              <ImpulseSpinner backColor="#666" frontColor="#E4002B" />
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(GuestHeader);

import React from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import GuestHeader from "./GuestHeader";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

const ProtectedLayout = () => {
  const { user } = useAuth();
  const location = useLocation();
  const path = location.pathname;
  const outlet = useOutlet();

  if (!user || user?.accessDetails === undefined) {
    return <Navigate to="/login" />;
  }

  const accessDetails = JSON.parse(user?.accessDetails);

  if (
    path === "/reports/attendance/contracted" &&
    accessDetails?.find(
      (item) => item.option_name === "Reports_Contract_Get"
    ) === undefined
  ) {
    return <Navigate to="/not-authorize" />;
  } else if (
    path === "/reports/attendance/performed" &&
    accessDetails?.find(
      (item) => item.option_name === "Reports_Performed_Get"
    ) === undefined
  ) {
    return <Navigate to="/not-authorize" />;
  } else if (
    path === "/reports/incident-reported" &&
    accessDetails?.find(
      (item) => item.option_name === "Reports_IncidentReported_Get"
    ) === undefined
  ) {
    return <Navigate to="/not-authorize" />;
  } else if (
    path === "/reports/attendance/additional-performed" &&
    accessDetails?.find(
      (item) => item.option_name === "Reports_AdditionalPerformed_Get"
    ) === undefined
  ) {
    return <Navigate to="/not-authorize" />;
  } else if (
    path === "/reports/attendance/unperformed" &&
    accessDetails?.find(
      (item) => item.option_name === "Reports_Unperformed_Get"
    ) === undefined
  ) {
    return <Navigate to="/not-authorize" />;
  } else if (
    path === "/reports/inspection" &&
    accessDetails?.find(
      (item) => item.option_name === "Reports_Inspection_Get"
    ) === undefined
  ) {
    return <Navigate to="/not-authorize" />;
  } else if (
    path === "/reports/manpower-overview" &&
    accessDetails?.find(
      (item) => item.option_name === "Reports_Manpower_Get"
    ) === undefined
  ) {
    return <Navigate to="/not-authorize" />;
  } else if (
    path === "/reports/location-exception" &&
    accessDetails?.find(
      (item) => item.option_name === "Reports_LocationException_Get"
    ) === undefined
  ) {
    return <Navigate to="/not-authorize" />;
  } else if (
    path === "/reports/attendance-approval" &&
    accessDetails?.find(
      (item) => item.option_name === "Reports_AttendanceApproval_Get"
    ) === undefined
  ) {
    return <Navigate to="/not-authorize" />;
  } else if (
    path === "/contact-us" &&
    accessDetails?.find((item) => item.option_name === "ContactUs") ===
      undefined
  ) {
    return <Navigate to="/not-authorize" />;
  } else if (
    path === "/reports/site-patrolling" &&
    accessDetails?.find(
      (item) => item.option_name === "Reports_SitePatrolling_TourReportsSubmit"
    ) === undefined
  ) {
    return <Navigate to="/not-authorize" />;
  }

  return (
    <div>
      <GuestHeader />
      {outlet}
      <div className="clearfix"></div>
      <Footer />
    </div>
  );
};

export default React.memo(ProtectedLayout);
